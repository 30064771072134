/**
Déclaration des fonts
*/
h1{
  text-align: center;
  /*font-style: italic;*/
  font-size: 1.875rem!important;
  margin-top: 2.5rem;
  /*text-transform: uppercase;*/
  color: white;
  /*color: #1d4851;*/
  font-weight: 900!important;
  padding-bottom: 30px;
}
h2{
  font-size: 18px;
  font-family: Mont;
  font-weight: 400!important;
}
h6{
  color: #00a79b;
  font-weight: bold;
}
.titreFrontNavBar h1{
  padding-top: 15px;
  padding-bottom:inherit!important;

}
.navbar{
  text-transform: none!important;
}
.li{
  line-height: 1;
}




body {
  margin: 0;
}

.categorieForm{
  align-content: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
}
.MuiDataGrid-cell{
  min-height: 100px;
}
span .MuiFormControlLabel-label{
  padding-top: 10px!important;
}
  /****
  *
  *  btn

  ****/

.with-after_icon-akto::after{
  font-family: icon!important;
  content: "\EA09"!important;
  line-height: 1.625;
  /*margin-left: 16px;*/
  font-size: 1.5rem;
  padding-left: 10px;

}
.btn-primary-filled{
  background-color: #00a79b!important;
  color: #ffffff!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 800!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
}
.btn-secondary-filled{
  background-color: #FFFFFF!important;
  color: #00a79b!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 800!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px !important;
  border: 1px solid!important;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  border-style: inherit;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
}


.btn-primary-filled-step2{
  background-color: #00a79b!important;
  color: #ffffff!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 600!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
  width: 200px;
}
.btn-secondary-filled-step2{
  background-color: #FFFFFF!important;
  color: #00a79b!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 600!important;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-width: 1px !important;
  border: 1px solid!important;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  border-style: inherit;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
  width: 200px;
}
/***
*GRID
*/
.gridCustom{
  text-align: center;
    padding-bottom: 20px!important;
}

/****
*
* navbar
**/

.navbar{
  font-size: 16px;
  line-height: 24px;
  font-weight: 800!important;
}

.containerTitre{
  background-color: #1d4851;
  padding-top: 0px;
  margin-top: 40px!important;
}
.borderForm{
  border: solid #e2e8f0;
  width: 40%;
  padding: 64px;
  margin-top: 50px;
  display: flex;
  margin: 50px auto;
}
.labelForm{
  font-weight: 700;
  font-size: .9375rem;
  --text-opacity: 1;
  color: #1d4851;
  color: rgba(29,72,81,var(--text-opacity));
  line-height: 1.75rem;
  padding-top: 20px!important;
}

.labelFormInput{
  padding-top: 20px;
  padding-bottom: 0px;
}
.boxForm{
  width: 100%!important;
}
boxForm{
  display: flex;
  align-items: center;

}
.containerTitreForm{
  background-color: #1d4851;
  margin-top: -8px!important;
  padding-top: 30px;
}
#filled-hidden-label-small{
  position: absolute;
  margin: 0;
}
.boxAjustement{
  padding-top: 20px;
  text-align: center;
  /*padding-bottom: 20px;*/
}
.boxAjustement2{
  padding-left: 96px;
}
.boxAjustement label{
  color: #1d4851;
  font-weight: 700;

  /*padding-bottom: 20px;*/
}

.containerCheckBoX{
  justify-content: center!important;
}
.categorieParent{
  color: #00a79b!important;

}
.categorieParent span{
  font-weight: 700!important;
}
.listEnfant{
  padding-left: 40px;

}
.listEnfant span{
  font-weight: 400;
}

.checkbox-label {
  display: flex;
  align-items: center;
    padding-bottom: 20px;
}

.checkbox-label .MuiCheckbox-root {
  margin-right: 10px;
}



.header-separator {
  width: 210px;
  display: flex;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 20px;
  padding-bottom: 20px;
  color: #00a79b!important;
}
.header-separator-left {
  width: 50%;
  border-style: solid;
  border-width: 0;
  border-top-width: 2px;
}

.header-separator-angle {
  width: 15px;
  position: relative;
  overflow: hidden;
  height: 10px;
  padding-bottom: 10px;
  margin-top: -2px;
}

.header-separator-angle:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  top: -5px;
  left: 1px;
  transform: rotate(
          45deg
  );
  border-style: solid;
  border-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-color: inherit;
  position: absolute;
}

.header-separator-right {
  width: 50%;
  border-style: solid;
  border-width: 0;
  border-top-width: 2px;
}
.light-blue .header-separator-left{--border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));}
.light-blue .header-separator-angle{  --border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));  }
.light-blue .header-separator-right{  --border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));  }


.boxFil{
    display: block !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
/*.checkbox-parent-selected svg path{*/
/*  fill: blue;*/
/*}*/
.checkBoxAgreement span{
  font-size: 12px;
}
. MuiGrid-container{
  margin-top: 0px!important;
}
.MuiDataGrid-columnHeaders{
  display: inherit!important;
}
p{
  font-family: Mont;
}
label{
  font-family: Mont;
}
.react-datepicker-wrapper{
  width: 7%;
}
.gridCustom2{
  justify-content: center;
}
tspan{
  /*font-size: 10px!important;*/
  font-family: Mont;
  fill: #1d4851!important;
}
.VictoryContainer{
  width: 50%!important;
}
.gridCustomStat{
  text-align: center;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
a{
  cursor: pointer;
}
div{
  font-family: Mont;
}
i{
  font-style: normal;
}
.tabuser{
    margin-left: 80px!important;
    margin-right: 80px!important;
}
.MuiDataGrid-cell{
  display: none;
}
/*.MuiGrid-item{*/
/*  padding-top: 20px!important;*/
/*}*/
/*.MuiGrid-container{*/
/*  padding-top: 20px!important;*/
/*}*/
.containerTitre2{
    padding-top: 40px;
    background-color: #1d4851;
}
.chart .recharts-cartesian-axis-tick-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px; /* Ajustez la valeur en fonction de vos besoins */
}

tspan{
    font-size: 15px;
}

.recharts-surface{
    vertical-align: unset!important;
}
