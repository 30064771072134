/**
Déclaration des fonts
*/
h1{
  text-align: center;
  /*font-style: italic;*/
  font-size: 1.875rem!important;
  margin-top: 2.5rem;
  /*text-transform: uppercase;*/
  color: white;
  /*color: #1d4851;*/
  font-weight: 900!important;
  padding-bottom: 30px;
}
h2{
  font-size: 18px;
  font-family: Mont;
  font-weight: 400!important;
}
h6{
  color: #00a79b;
  font-weight: bold;
}
.titreFrontNavBar h1{
  padding-top: 15px;
  padding-bottom:inherit!important;

}
.navbar{
  text-transform: none!important;
}
.li{
  line-height: 1;
}




body {
  margin: 0;
}

.categorieForm{
  align-content: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
}
.MuiDataGrid-cell{
  min-height: 100px;
}
span .MuiFormControlLabel-label{
  padding-top: 10px!important;
}
  /****
  *
  *  btn

  ****/

.with-after_icon-akto::after{
  font-family: icon!important;
  content: "\EA09"!important;
  line-height: 1.625;
  /*margin-left: 16px;*/
  font-size: 1.5rem;
  padding-left: 10px;

}
.btn-primary-filled{
  background-color: #00a79b!important;
  color: #ffffff!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 800!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,-webkit-transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
}
.btn-secondary-filled{
  background-color: #FFFFFF!important;
  color: #00a79b!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 800!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px !important;
  border: 1px solid!important;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  border-style: inherit;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,-webkit-transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
}


.btn-primary-filled-step2{
  background-color: #00a79b!important;
  color: #ffffff!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 600!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,-webkit-transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
  width: 200px;
}
.btn-secondary-filled-step2{
  background-color: #FFFFFF!important;
  color: #00a79b!important;
  /*font-size: 15px!important;*/
  /*line-height: 1.625!important;*/
  font-weight: 600!important;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-width: 1px !important;
  border: 1px solid!important;
  line-height: 1.5rem;
  font-weight: 800;
  font-size: .9375rem;
  border-radius: 6px;
  border-style: inherit;
  /*background-color: currentColor;*/
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,-webkit-transform;
  transition-duration: .2s;
  cursor: pointer;
  text-decoration: none;
  padding: 2px .75rem 0!important;
  text-transform: none!important;
  width: 200px;
}
/***
*GRID
*/
.gridCustom{
  text-align: center;
    padding-bottom: 20px!important;
}

/****
*
* navbar
**/

.navbar{
  font-size: 16px;
  line-height: 24px;
  font-weight: 800!important;
}

.containerTitre{
  background-color: #1d4851;
  padding-top: 0px;
  margin-top: 40px!important;
}
.borderForm{
  border: solid #e2e8f0;
  width: 40%;
  padding: 64px;
  margin-top: 50px;
  display: flex;
  margin: 50px auto;
}
.labelForm{
  font-weight: 700;
  font-size: .9375rem;
  --text-opacity: 1;
  color: #1d4851;
  color: rgba(29,72,81,var(--text-opacity));
  line-height: 1.75rem;
  padding-top: 20px!important;
}

.labelFormInput{
  padding-top: 20px;
  padding-bottom: 0px;
}
.boxForm{
  width: 100%!important;
}
boxForm{
  display: flex;
  align-items: center;

}
.containerTitreForm{
  background-color: #1d4851;
  margin-top: -8px!important;
  padding-top: 30px;
}
#filled-hidden-label-small{
  position: absolute;
  margin: 0;
}
.boxAjustement{
  padding-top: 20px;
  text-align: center;
  /*padding-bottom: 20px;*/
}
.boxAjustement2{
  padding-left: 96px;
}
.boxAjustement label{
  color: #1d4851;
  font-weight: 700;

  /*padding-bottom: 20px;*/
}

.containerCheckBoX{
  justify-content: center!important;
}
.categorieParent{
  color: #00a79b!important;

}
.categorieParent span{
  font-weight: 700!important;
}
.listEnfant{
  padding-left: 40px;

}
.listEnfant span{
  font-weight: 400;
}

.checkbox-label {
  display: flex;
  align-items: center;
    padding-bottom: 20px;
}

.checkbox-label .MuiCheckbox-root {
  margin-right: 10px;
}



.header-separator {
  width: 210px;
  display: flex;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 20px;
  padding-bottom: 20px;
  color: #00a79b!important;
}
.header-separator-left {
  width: 50%;
  border-style: solid;
  border-width: 0;
  border-top-width: 2px;
}

.header-separator-angle {
  width: 15px;
  position: relative;
  overflow: hidden;
  height: 10px;
  padding-bottom: 10px;
  margin-top: -2px;
}

.header-separator-angle:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  top: -5px;
  left: 1px;
  -webkit-transform: rotate(
          45deg
  );
          transform: rotate(
          45deg
  );
  border-style: solid;
  border-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-color: inherit;
  position: absolute;
}

.header-separator-right {
  width: 50%;
  border-style: solid;
  border-width: 0;
  border-top-width: 2px;
}
.light-blue .header-separator-left{--border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));}
.light-blue .header-separator-angle{  --border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));  }
.light-blue .header-separator-right{  --border-opacity: 1;
  border-color: #00a79b;
  border-color: rgba(0,167,155,var(--border-opacity));  }


.boxFil{
    display: block !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
/*.checkbox-parent-selected svg path{*/
/*  fill: blue;*/
/*}*/
.checkBoxAgreement span{
  font-size: 12px;
}
. MuiGrid-container{
  margin-top: 0px!important;
}
.MuiDataGrid-columnHeaders{
  display: inherit!important;
}
p{
  font-family: Mont;
}
label{
  font-family: Mont;
}
.react-datepicker-wrapper{
  width: 7%;
}
.gridCustom2{
  justify-content: center;
}
tspan{
  /*font-size: 10px!important;*/
  font-family: Mont;
  fill: #1d4851!important;
}
.VictoryContainer{
  width: 50%!important;
}
.gridCustomStat{
  text-align: center;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
a{
  cursor: pointer;
}
div{
  font-family: Mont;
}
i{
  font-style: normal;
}
.tabuser{
    margin-left: 80px!important;
    margin-right: 80px!important;
}
.MuiDataGrid-cell{
  display: none;
}
/*.MuiGrid-item{*/
/*  padding-top: 20px!important;*/
/*}*/
/*.MuiGrid-container{*/
/*  padding-top: 20px!important;*/
/*}*/
.containerTitre2{
    padding-top: 40px;
    background-color: #1d4851;
}
.chart .recharts-cartesian-axis-tick-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px; /* Ajustez la valeur en fonction de vos besoins */
}

tspan{
    font-size: 15px;
}

.recharts-surface{
    vertical-align: unset!important;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-BoldItalic.4697db05.eot);
    src: local('Mont Bold Italic'), local('Mont-BoldItalic'),
        url(/static/media/Mont-BoldItalic.4697db05.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-BoldItalic.04e25b64.woff2) format('woff2'),
        url(/static/media/Mont-BoldItalic.6a53a942.woff) format('woff'),
        url(/static/media/Mont-BoldItalic.3f92a266.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Regular.a3720bc4.eot);
    src: local('Mont Regular'), local('Mont-Regular'),
        url(/static/media/Mont-Regular.a3720bc4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Regular.c8890d35.woff2) format('woff2'),
        url(/static/media/Mont-Regular.d78a9f27.woff) format('woff'),
        url(/static/media/Mont-Regular.5e507545.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Heavy.83f74947.eot);
    src: local('Mont Heavy'), local('Mont-Heavy'),
        url(/static/media/Mont-Heavy.83f74947.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Heavy.7809de50.woff2) format('woff2'),
        url(/static/media/Mont-Heavy.d8d48df1.woff) format('woff'),
        url(/static/media/Mont-Heavy.846d0e60.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-BlackItalic.4a2255da.eot);
    src: local('Mont Black Italic'), local('Mont-BlackItalic'),
        url(/static/media/Mont-BlackItalic.4a2255da.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-BlackItalic.95bedb06.woff2) format('woff2'),
        url(/static/media/Mont-BlackItalic.02bef0c1.woff) format('woff'),
        url(/static/media/Mont-BlackItalic.7b64e7d6.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Book';
    src: url(/static/media/Mont-Book.37a01b4c.eot);
    src: local('Mont Book'), local('Mont-Book'),
        url(/static/media/Mont-Book.37a01b4c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Book.d9853ae1.woff2) format('woff2'),
        url(/static/media/Mont-Book.69a0de80.woff) format('woff'),
        url(/static/media/Mont-Book.788d5a3e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Heavy DEMO';
    src: url(/static/media/Mont-HeavyDEMO.ef0026d0.eot);
    src: local('Mont Heavy DEMO'), local('Mont-HeavyDEMO'),
        url(/static/media/Mont-HeavyDEMO.ef0026d0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-HeavyDEMO.ed53ba1c.woff2) format('woff2'),
        url(/static/media/Mont-HeavyDEMO.9dbba585.woff) format('woff'),
        url(/static/media/Mont-HeavyDEMO.cc7a09c5.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-ExtraLightItalic.c722ebd8.eot);
    src: local('Mont ExtraLight Italic'), local('Mont-ExtraLightItalic'),
        url(/static/media/Mont-ExtraLightItalic.c722ebd8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-ExtraLightItalic.49e8ba4a.woff2) format('woff2'),
        url(/static/media/Mont-ExtraLightItalic.018aa640.woff) format('woff'),
        url(/static/media/Mont-ExtraLightItalic.af08fdc3.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-SemiBold.e30247ca.eot);
    src: local('Mont SemiBold'), local('Mont-SemiBold'),
        url(/static/media/Mont-SemiBold.e30247ca.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-SemiBold.1b16a700.woff2) format('woff2'),
        url(/static/media/Mont-SemiBold.8a9d4ba0.woff) format('woff'),
        url(/static/media/Mont-SemiBold.7c780cfc.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Bold.889e996e.eot);
    src: local('Mont Bold'), local('Mont-Bold'),
        url(/static/media/Mont-Bold.889e996e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Bold.cb7ce747.woff2) format('woff2'),
        url(/static/media/Mont-Bold.aecbbc6b.woff) format('woff'),
        url(/static/media/Mont-Bold.2d770248.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Black.ddc81466.eot);
    src: local('Mont Black'), local('Mont-Black'),
        url(/static/media/Mont-Black.ddc81466.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Black.1c9cd8a7.woff2) format('woff2'),
        url(/static/media/Mont-Black.f126d152.woff) format('woff'),
        url(/static/media/Mont-Black.1683963e.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-RegularItalic.9cb3c9d4.eot);
    src: local('Mont Regular Italic'), local('Mont-RegularItalic'),
        url(/static/media/Mont-RegularItalic.9cb3c9d4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-RegularItalic.474ab40f.woff2) format('woff2'),
        url(/static/media/Mont-RegularItalic.22d3a51c.woff) format('woff'),
        url(/static/media/Mont-RegularItalic.02b2a75e.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont ExtraLight DEMO';
    src: url(/static/media/Mont-ExtraLightDEMO.10b965f6.eot);
    src: local('Mont ExtraLight DEMO'), local('Mont-ExtraLightDEMO'),
        url(/static/media/Mont-ExtraLightDEMO.10b965f6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-ExtraLightDEMO.c6878868.woff2) format('woff2'),
        url(/static/media/Mont-ExtraLightDEMO.f0e1621b.woff) format('woff'),
        url(/static/media/Mont-ExtraLightDEMO.8a408ba1.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Book';
    src: url(/static/media/Mont-BookItalic.f6e315dd.eot);
    src: local('Mont Book Italic'), local('Mont-BookItalic'),
        url(/static/media/Mont-BookItalic.f6e315dd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-BookItalic.d1fcce39.woff2) format('woff2'),
        url(/static/media/Mont-BookItalic.64c948ba.woff) format('woff'),
        url(/static/media/Mont-BookItalic.b3cca784.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-HeavyItalic.be15b5ac.eot);
    src: local('Mont Heavy Italic'), local('Mont-HeavyItalic'),
        url(/static/media/Mont-HeavyItalic.be15b5ac.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-HeavyItalic.fcc71047.woff2) format('woff2'),
        url(/static/media/Mont-HeavyItalic.cb38fd34.woff) format('woff'),
        url(/static/media/Mont-HeavyItalic.3a1b25a8.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-ThinItalic.10708783.eot);
    src: local('Mont Thin Italic'), local('Mont-ThinItalic'),
        url(/static/media/Mont-ThinItalic.10708783.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-ThinItalic.02073bc1.woff2) format('woff2'),
        url(/static/media/Mont-ThinItalic.69412306.woff) format('woff'),
        url(/static/media/Mont-ThinItalic.2506de5e.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-SemiBoldItalic.0af02716.eot);
    src: local('Mont SemiBold Italic'), local('Mont-SemiBoldItalic'),
        url(/static/media/Mont-SemiBoldItalic.0af02716.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-SemiBoldItalic.c70f84bf.woff2) format('woff2'),
        url(/static/media/Mont-SemiBoldItalic.093e6aa8.woff) format('woff'),
        url(/static/media/Mont-SemiBoldItalic.4b171a4b.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Hairline';
    src: url(/static/media/Mont-Hairline.0be7cfa2.eot);
    src: local('Mont Hairline'), local('Mont-Hairline'),
        url(/static/media/Mont-Hairline.0be7cfa2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Hairline.664b4b0d.woff2) format('woff2'),
        url(/static/media/Mont-Hairline.3626ed86.woff) format('woff'),
        url(/static/media/Mont-Hairline.9c1ca999.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Hairline';
    src: url(/static/media/Mont-HairlineItalic.a6ca67e7.eot);
    src: local('Mont Hairline Italic'), local('Mont-HairlineItalic'),
        url(/static/media/Mont-HairlineItalic.a6ca67e7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-HairlineItalic.2c9601c8.woff2) format('woff2'),
        url(/static/media/Mont-HairlineItalic.16b19879.woff) format('woff'),
        url(/static/media/Mont-HairlineItalic.79dc5ceb.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-LightItalic.151f9d0f.eot);
    src: local('Mont Light Italic'), local('Mont-LightItalic'),
        url(/static/media/Mont-LightItalic.151f9d0f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-LightItalic.547e4df5.woff2) format('woff2'),
        url(/static/media/Mont-LightItalic.2a3695b9.woff) format('woff'),
        url(/static/media/Mont-LightItalic.eadfde6f.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Thin.c2721716.eot);
    src: local('Mont Thin'), local('Mont-Thin'),
        url(/static/media/Mont-Thin.c2721716.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Thin.f74d5188.woff2) format('woff2'),
        url(/static/media/Mont-Thin.29e30be5.woff) format('woff'),
        url(/static/media/Mont-Thin.a19a25f4.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-ExtraLight.217ac81b.eot);
    src: local('Mont ExtraLight'), local('Mont-ExtraLight'),
        url(/static/media/Mont-ExtraLight.217ac81b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-ExtraLight.089572ba.woff2) format('woff2'),
        url(/static/media/Mont-ExtraLight.81525389.woff) format('woff'),
        url(/static/media/Mont-ExtraLight.f290d052.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Light.28a75c73.eot);
    src: local('Mont Light'), local('Mont-Light'),
        url(/static/media/Mont-Light.28a75c73.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mont-Light.1319382a.woff2) format('woff2'),
        url(/static/media/Mont-Light.9bfa916f.woff) format('woff'),
        url(/static/media/Mont-Light.630d12d4.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'icon';
    src: url(/static/media/icon.597bf323.eot);
    src: local('icon'),
    url(/static/media/icon.597bf323.eot?#iefix) format('embedded-opentype'),
    url(/static/media/icon.b7c5f94f.woff2) format('woff2'),
    url(/static/media/icon.962aab6c.woff) format('woff'),
    url(/static/media/icon.64e41e6e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

